.header {
  width: 100%;
  height: 89px;
}
.header_inner {
  height: 89px;
  max-width: 990px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.header_metwatch {
  width: 150px;
}
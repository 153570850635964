html, body, #root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}
#page-content {
  flex: 1 0 auto;
}
#footer {
  flex-shrink: 0;
}